<template>
  <div class="znzz">
    <div class="header">
      <div class="zhuti">
        <div class="h1">网络安全</div>
        <div class="h2">
          <p>数据信息化安全，移动端APP安全，用户信息安全等。</p>
        </div>
      </div>
    </div>
    <div class="titles pad56" id="m1">产品功能</div>
    <div class="cj">
      <div class="zhuti">
        <div class="cjlist "  v-for="(li,index) in yycjList" :key="index">
          <div class="mag32">
            <div class="name">{{li.title}}</div>
            <div class="desc">{{li.titleSub}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg2">
      <div class="zhuti szls">
        <div class="titles" style="color: #fff" id="m2">网络安全产品主要功能</div>
        <el-row class="tabs">
            <el-col span="4" :offset="index!=0?1:0" v-for="(li,index) in zygnList" :key="index">
              <div class="tablist" :class="{'isactive':number==index}" @click="gotabs(index)">
                <div class="tabimage"><el-image :src="li.icon"></el-image></div>
                <div>{{li.title}}</div>
              </div>
            </el-col>
        </el-row>
        <div class="anli flex">
          <div class="anlileft">
            <div class="anlit">{{zygnList[number].title}}</div>
            <div>{{zygnList[number].titleSub}}</div>
          </div>
          <div class="anliimage"><el-image :src="zygnList[number].image"></el-image></div>
        </div>
      </div>
    </div>
    <div class="bg">
      <div>
        <div class="titles" id="m5">优质认证企业推荐</div>
        <div class="zhuti">
          <div class="qiye">
            <div class="qiyelist flex" v-for="(li,index) in qyrzList" :key="index">
              <div class="qiyeimage"><el-image :src="li.image"></el-image></div>
              <div style="width: 400px">
                <div class="qiyetitle text-overflow">{{li.title}}</div>
                <div><el-tag type="info" style="margin-right: 5px" size="small" v-for="(item,i) in li.titleSub?li.titleSub.split(','):''" :key="i">{{item}}</el-tag></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg4">
      <div class="titles">需求咨询留言</div>
      <div class="advice">
          <Advice></Advice>
      </div>
    </div>
    <div class="zhuti">
      <div class="more">
        <div class="titles" id="m5">第三方推荐</div>
        <MoreList :moreList="moreList"></MoreList>
      </div>
    </div>
  </div>
</template>

<script>
import {networksecurityindex, productionmanagementindex} from "../../request/moudle/znzz";
import Advice from "../common/advice.vue";
import MoreList from "../common/moreList.vue";
export default {
  name: "",
  components:{
    Advice,
    MoreList
  },
  data(){
    return  {
      datas:'',
      number:0,
      moreList:[],
      zygnList:[],
      yycjList:[],
      qyrzList:[]
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    gotabs(index){
      this.number=index
    },
    loadconfig(){
      this.$api.znzz.networksecurityindex().then((res)=>{
        this.datas=res.data
        this.moreList=res.data.moreList
        this.zygnList=res.data.zygnList
        this.yycjList=res.data.yycjList
        this.qyrzList=res.data.qyrzList
      })
    },
   //推荐
     tuijian(item){
       if(item.title == "商城管理"){
          window.open('http://shopcmer.bs-icloud.com:8000/login?redirect=%2Fdashboard','_blank')
       }else if(item.title == "仓库管理"){
          window.open('http://admin.czicloud-sy.cn:8000/#/bsLogin','_blank')
       }else if(item.title == "标识解析"){
          window.open('https://www.cz-icloud.com/trial','_blank')
       }else if(item.title == "设备管理"){
          // window.open(config.adminhref+'/#/myiframe/urlPath?','_blank')
       }
    },
    remove(e){
      let hdimage=this.$refs.hdimage
      for (const val of hdimage){
        val.className='hdimage display'
      }
      e.target.className='hdimage remove-class show'
    },
  }
}
</script>

<style lang="scss" scoped>
.znzz{
  background: #fff;
}
.mag32{
  margin: 24px;
  width: 410px;
}
.qiye{
  overflow: hidden;
  .qiyelist{
    float: left;
    margin: 10px;
    width: 580px;
    height: 140px;
    background: #fff;
    border-radius: 8px;
    .qiyeimage{
      width: 100px;
      height: 100px;
      margin: 18px;
    }
    .qiyetitle{
      font-size: 16px;
      font-weight: bolder;
      margin: 35px 0 20px 0;

    }
  }
}

.szls{
  .anli{
    width: 1200px;
  }
  .anlileft{
    width: 440px;
    color: #fff;
  }
  .anlit{
    font-size: 20px;
    padding: 40px 0;
  }
  .anliimage{
    width: 660px;
    height: 300px;
    margin-left: 60px;
  }
}
.cj{
  overflow: hidden;
  margin-bottom: 56px;
  .cjlist{
    width: 384px;
    height: 120px;
    background: url("../../assets/znzz/cjlist.png") no-repeat;
    background-size: 100% 100%;
    float: left;
    position: relative;
    margin: 8px;
  }
  .name{
    font-size: 18px;
    font-weight: bolder;
    color: #1D1D1D;
    margin-bottom: 12px;
  }
  .desc{
    color: #999;
  }
}
.header{
  height: 230px;
  background: url("../../assets/banner/anquan.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  color: #fff;
  .h1{
    font-size: 42px;
    margin-top: 25px;
  }
  .h2{
    font-size: 20px;
    line-height: 30px;
    margin-top: 15px;
  }
}
.tabs{
  overflow: hidden;
  margin-bottom: 35px;
  .tablist{
    width: 160px;
    height: 160px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    border-radius: 6px;
  }
  .tabimage{
    display: inline-block;
    width: 80px;
    height: 80px;
    margin: 12px  auto;
  }
  .isactive{
    background: rgba(255,255,255,0.2);
  }
}
.more{
  overflow: hidden;
  padding: 56px;
  .mlist{
    text-align: center;
    float: left;
    width: 25%;
  }
  .minage{
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
}
.bg{
  background: #F4F9FE;
  padding:56px;
}
.bg2{
  background: url("../../assets/znzz/bg2.png") no-repeat;
  background-size: 100% 100%;
  padding: 56px 0;
}
.bg3{
  padding: 56px;
  .images{

  }
}
.allfangan{
  overflow: hidden;
  .fangan{
    float: left;
    width: 384px;
    margin: 8px;
    .image{
      width: 100%;
      height: 220px;
      margin-right: 32px;
    }
    .title{
      color: #1D1D1D;
      font-weight: bolder;
      font-size: 16px;
      margin: 15px 0;
    }
    .h2{
      height: 37px;
      color: #999;
    }
  }
}
.bg4{
  background: #FFF;
  padding: 56px 0 0 0;
  .advice {
   width: 1120px;
   height: 80px;
   padding: 50px 40px;
   margin: 0 auto 30px auto;
   border-radius: 6;
   background: #fff;
   box-shadow: 1px 1px 5px rgba(0,0,0,.1);
  }
}
.bg1{
  background: url("../../assets/znzz/bg1.png") no-repeat;
  background-size: 100% 100%;
  height: 500px;
}

.zhuti{
  width: 1200px;
  margin: 0px auto;
}
.titles{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  padding-bottom: 56px;
  color: #1D1D1D;
}
.pad56{
  padding-top: 56px;
}
</style>